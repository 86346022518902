import { handleHttpError, baseUrl, getToken } from "./utils";

const axios = require("axios").default;

async function fetchAllRegions() {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/regions`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    let counties = response.data.data[0].counties;
    return counties;
  } catch (err) {
    const error = err.error.message
      ? `Error fetching Regions: ${err}`
      : "An error occurred fetching Regions. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchRoadsList() {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/data/roads-list`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response?.data?.data;
  } catch (err) {
    const error = err.error.message
      ? `Error fetching Roads Lists: ${err}`
      : "An error occurred fetching Roads Lists. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchRoadsData(roadsList) {
  let roadListUrl = "";

  roadsList.forEach((road) => {
    roadListUrl += `names[]=${road}&`;
  });
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/data/roads?${roadListUrl}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (err) {
    const error = err.error.message
      ? `Error fetching Roads Data: ${err}`
      : "An error occurred fetching Roads Data. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

export { fetchAllRegions, fetchRoadsList, fetchRoadsData };
