export default {
  getBillboards(state) {
    return state.billboards;
  },
  getUnsuitableBillboards(state) {
    return state.unsuitableBillboards;
  },
  getSuitableBillboards(state) {
    return state.suitableBillboards;
  },
  getPopulation(state) {
    return state.population;
  },
  getSubCountySpending(state) {
    return state.sub_county_spending;
  },
  getWardSpending(state) {
    return state.ward_spending;
  },
  getBbCart(state) {
    return state.bbCart;
  },
  getSearchResults(state) {
    return state.search;
  },
  getSearchCountsByCounty(state) {
    return state.searchCountsByCounty;
  },
  getBrands(state) {
    return state.brands;
  },
  getOwners(state) {
    return state.owners;
  },
  getCandidates(state) {
    return state.candidates;
  },
  getBookedBBs(state) {
    return state.bookedBBs;
  },
  getAvailableBBs(state) {
    return state.availableBBs;
  },
  getSoonAvailableBBs(state) {
    return state.soonAvailableBBs;
  },
  getContractExpiredBBs(state) {
    return state.contractExpiredBBs;
  },
};
