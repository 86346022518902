import {
  fetchBbsByBbox,
  fetchAllBbs,
  fetchPop,
  fetchBBPaginated,
  deleteBB,
  fetchBB,
  addBillboard,
  updateBillboard,
  addBbToCart,
  fetchBbCart,
  removeBbFromCart,
  searchBillboards,
  fetchBrands,
  fetchOwners,
  fetchMyBbs,
  fetchBriefBbs,
  fetchWardSpending,
  fetchSubCountySpending,
  fetchBookedBillboards,
  fetchAvailableBillboards,
  fetchExpiredContractBillboards,
  fetchSoonToBeAvailableBillboards,
} from "@/helpers/billboards";

import { fetchCandidates, updateCandidates } from "@/helpers/candidate";

export default {
  async fetchAllBbs({ commit }) {
    const billboards = await fetchAllBbs();
    return commit("setBillboards", billboards);
  },

  async fetchMyBbs({ commit }, payload) {
    const bb = await fetchMyBbs(payload);
    return commit("setBillboards", bb);
  },

  async fetchBriefBbs({ commit }, payload) {
    const bbs = await fetchBriefBbs(payload);
    commit("setSuitableBillboards", bbs.suitable_billboards);
    commit("setUnsuitableBillboards", bbs.unsuitable_billboards);
  },

  async fetchBB(_, id) {
    return await fetchBB(id);
  },

  async fetchPop({ commit }, { lat, long }) {
    const populationData = await fetchPop({ lat, long });
    return commit("setPopulation", populationData);
  },

  async fetchWardSpending({ commit }, { lat, long }) {
    const wardSpending = await fetchWardSpending({ lat, long });
    return commit("setWardSpending", wardSpending);
  },

  async fetchSubCountySpending({ commit }, { lat, long }) {
    const subCountySpending = await fetchSubCountySpending({ lat, long });
    return commit("setSubCountySpending", subCountySpending);
  },

  async fetchBbsByBbox({ commit }, payLoad) {
    const billboards = await fetchBbsByBbox(payLoad);
    return commit("setBillboards", billboards);
  },

  async fetchBBPaginated(_, payload) {
    return await fetchBBPaginated(payload);
  },

  async addBillboard({ dispatch, rootGetters }, payload) {
    await addBillboard(payload);
    await dispatch("fetchMyBbs", rootGetters.getCurrentUser.userId);
  },

  async updateBillboard({ dispatch, rootGetters }, payload) {
    await updateBillboard(payload);

    await dispatch("fetchMyBbs", rootGetters.getCurrentUser.userId);
  },

  async deleteBB(_, id) {
    await deleteBB(id);
  },

  async addBbToCart({ commit }, id) {
    const bbCart = await addBbToCart(id);
    return commit("setBBToCart", bbCart);
  },

  async fetchBbCart({ commit }) {
    const bbCart = await fetchBbCart();
    return commit("setBBToCart", bbCart);
  },

  async removeBbFromCart({ commit }, id) {
    const bbCart = await removeBbFromCart(id);
    return commit("setBBToCart", bbCart);
  },

  async searchBillboards({ commit }, payLoad) {
    const { billboards, countsByCounty } = await searchBillboards(payLoad);
    commit("setSearchResults", billboards);
    commit("setSearchCountsByCounty", countsByCounty);
  },

  async fetchBrands({ commit }) {
    const results = await fetchBrands();
    return commit("setBrands", results);
  },

  async fetchOwners({ commit }) {
    const res = await fetchOwners();
    return commit("setOwners", res);
  },

  async fetchCandidates({ commit }, payload) {
    const candidates = await fetchCandidates(payload);
    return commit("setCandidates", candidates);
  },

  async updateCandidates(_, payload) {
    return await updateCandidates(payload);
  },

  async fetchAvailableBillboards({ commit }, payload) {
    const bbs = await fetchAvailableBillboards(payload);
    return commit("setAvailableBBs", bbs);
  },

  async fetchSoonToBeAvailableBillboards({ commit }, payload) {
    const bbs = await fetchSoonToBeAvailableBillboards(payload);
    return commit("setSoonAvailableBBs", bbs);
  },

  async fetchExpiredContractBillboards({ commit }, payload) {
    const bbs = await fetchExpiredContractBillboards(payload);
    return commit("setContractExpiredBBs", bbs);
  },
  async fetchBookedBillboards({ commit }, payload) {
    const bbs = await fetchBookedBillboards(payload);
    return commit("setBookedBBs", bbs);
  },
};
