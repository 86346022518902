import { baseUrl, handleHttpError, getToken, reportSuccess } from "./utils";
const axios = require("axios").default;

async function fetchBrandCategories() {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/brands/brand-categories/`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? `${err.response.data.error.message}`
      : "An error occurred fetching Brand Categories. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchBrandCompetitor(brandId) {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${baseUrl}/brands/competitor/${brandId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? ` ${err.response.data.error.message}`
      : "An error occurred fetching Brand Competitors. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchUserBrands(userId) {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/brands/user/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? ` ${err.response.data.error.message}`
      : "An error occurred fetching User Brands. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchAllBrands() {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/brands/`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? ` ${err.response.data.error.message}`
      : "An error occurred fetching All Brands. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchBrand(brandId) {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/brands/${brandId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error = err.response.data
      ? ` ${err.response.data.error.message}`
      : "An error occurred fetching Brand. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function addBrand(payload) {
  const token = await getToken();
  try {
    await axios.post(`${baseUrl}/brands`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const message = `Added brand successfully`;
    return reportSuccess(message);
  } catch (err) {
    const message = err.response.data
      ? `Error Adding brand: ${err.response.data.error}`
      : "An error was encountered trying to upload the brand. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function updateBrand(payload) {
  const token = await getToken();
  try {
    await axios.put(`${baseUrl}/brands/${payload.id}`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const message = `Updated Brand successfully`;
    return reportSuccess(message);
  } catch (err) {
    const message =
      `Error Updating Brand: ${err.response.data.error}` ||
      "An error was encountered trying to upload the Brand. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function deleteBrand(id) {
  const token = await getToken();
  try {
    await axios.delete(`${baseUrl}/brands/brand-owner/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const message = `Brand deleted successfully`;
    return reportSuccess(message);
  } catch (err) {
    const error =
      `Error Deleting Brand: ${err.response.data.error}` ||
      "An error occurred Deleting Brand.Please try again or Contact the System Admin ";
    return handleHttpError(error);
  }
}

export {
  fetchBrandCategories,
  fetchBrandCompetitor,
  fetchUserBrands,
  fetchAllBrands,
  fetchBrand,
  addBrand,
  updateBrand,
  deleteBrand,
};
